import { Text, TextBodyProps } from "@opensea/ui-kit"
import styled from "styled-components"
import { Skeleton } from "@opensea/ui-kit"
import type { Active } from "../../types"

export const NumberLabel = styled(Text.Body).attrs({
  size: "medium",
})<Active & TextBodyProps>`
  color: ${props =>
    props.active
      ? props.theme.colors.text.primary
      : props.theme.colors.text.secondary};
  align-self: center;
`

export const NumberLabelSkeleton = styled(Skeleton.Line).attrs({
  height: "14px",
  marginLeft: "8px",
  width: "20px",
})`
  align-self: center;
`
