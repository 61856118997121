import React from "react"
import { Icon } from "@opensea/ui-kit"
import styled from "styled-components"
import { IS_SERVER } from "@/constants/environment"
import { Dropdown } from "@/design-system/Dropdown"
import { Menu } from "@/design-system/Menu"
import { useRouter } from "@/hooks/useRouter"
import { shortSymbolDisplay } from "@/lib/helpers/numberUtils"
import { stringifyQueryParams } from "@/lib/helpers/urls"
import type { TabDropdownProps } from "../../types"
import { MenuItem } from "../MenuItem"

export const TabDropdown = ({
  label,
  items,
  active,
  baseUrl,
  isProfilePage,
}: TabDropdownProps) => {
  const router = useRouter()
  return (
    <Dropdown
      appendTo={IS_SERVER ? undefined : document.body}
      content={({ List, Item, close }) => (
        <List data-testid="TabNavigation--dropdown">
          {items.map(item => {
            const href = baseUrl
              ? isProfilePage
                ? `${baseUrl}/${item.id}`
                : `${baseUrl}${
                    item.path ??
                    stringifyQueryParams({
                      ...router.queryParams,
                      tab: item.id,
                    })
                  }`
              : undefined

            return (
              <Item href={href} key={item.id} onClick={close}>
                <Item.Content>
                  <Item.Title> {item.label}</Item.Title>
                </Item.Content>
                <Item.Action>
                  {!!item.count && shortSymbolDisplay(item.count)}
                </Item.Action>
              </Item>
            )
          })}
        </List>
      )}
      maxHeight="358px"
      popperOptions={{
        modifiers: [
          {
            name: "flip",
            enabled: false,
          },
        ],
      }}
    >
      <MenuItem
        $active={active}
        data-testid="profile-nav-more"
        direction="horizontal"
        padding={{ _: "12px 16px", lg: "12px 24px" }}
      >
        <Label>{label}</Label>
        <Menu.Side>
          <Icon
            className={active ? undefined : "text-secondary"}
            value="expand_more"
            weight={600}
          />
        </Menu.Side>
      </MenuItem>
    </Dropdown>
  )
}

const Label = styled(Menu.Title)`
  margin-right: 0px;
  white-space: nowrap;
`
