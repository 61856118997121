import React from "react"
import {
  Flex,
  Media,
  SpaceBetween,
  classNames,
  useIsLessThanLg,
  Skeleton,
} from "@opensea/ui-kit"
import styled, { css } from "styled-components"
import { Block } from "@/design-system/Block"
import { Menu } from "@/design-system/Menu"
import { useTranslate } from "@/hooks/useTranslate"
import { media } from "@/styles/styleUtils"
import { TabDropdown } from "./components/TabDropdown"
import { TabItem } from "./components/TabItem"
import type { TabNavigationProps } from "./types"

export const TabNavigation = ({
  tabs,
  currentTab,
  handleClick,
  disableScroll,
  baseUrl,
  isLoading,
  endEnhancer,
  renderLabel,
  isProfilePage,
  showBorder = true,
  isStuck = false,
  responsive,
  ...blockProps
}: TabNavigationProps) => {
  const t = useTranslate("phoenix")

  const isLessThanLg = useIsLessThanLg()

  if (isLoading) {
    return (
      <Flex className="w-full">
        <Skeleton.Row>
          <Flex>
            <Media greaterThanOrEqual="sm">
              <Skeleton.Line className="h-11 w-[700px]" />
            </Media>
            <Media lessThan="sm">
              <Skeleton.Line className="h-8 w-[300px]" />
            </Media>
          </Flex>
        </Skeleton.Row>
      </Flex>
    )
  }

  return (
    <Flex className="w-full" data-testid="ProfilePage--navbar">
      <Block as="nav" className="w-full">
        <SpaceBetween
          className={classNames(
            "duration-[250ms] items-end py-4 transition-all lg:py-6",
            isStuck && "pb-3",
          )}
        >
          <StyledNavbar $disableScroll={disableScroll} direction="horizontal">
            <Flex
              {...blockProps}
              className={classNames(
                "m-0 -ml-2",
                isLessThanLg ? "gap-1" : "gap-2",
              )}
            >
              {tabs.map((tab, idx) => {
                if (Array.isArray(tab)) {
                  const isActive =
                    tab.filter(t => t.id === currentTab).length > 0
                  return (
                    <TabDropdown
                      active={isActive}
                      baseUrl={baseUrl}
                      currentTab={currentTab}
                      isProfilePage={isProfilePage}
                      items={tab}
                      key="more-dropdown"
                      label={t("more", "More")}
                    />
                  )
                }
                return (
                  <TabItem
                    active={tab.id === currentTab}
                    baseUrl={tab.urlOverride ?? baseUrl}
                    count={tab.count}
                    disabled={tab.disabled}
                    error={tab.error}
                    handleClick={tab.handleClick ?? handleClick}
                    id={tab.id}
                    isProfilePage={isProfilePage}
                    key={tab.id || idx}
                    label={tab.label}
                    launchStatus={tab.launchStatus}
                    path={tab.path}
                    renderLabel={tab.renderLabel ?? renderLabel}
                    responsive={responsive}
                    testId={tab.testId}
                  />
                )
              })}
            </Flex>
          </StyledNavbar>
          {endEnhancer}
        </SpaceBetween>
        {showBorder && <BottomBorder />}
      </Block>
    </Flex>
  )
}

const BottomBorder = styled(Block)`
  border-bottom: 1px solid
    ${props => props.theme.colors.components.border.level1};

  ${media({
    sm: css`
      margin: unset;
    `,
  })}
`
const StyledNavbar = styled(Menu.Base)<{ $disableScroll: boolean | undefined }>`
  justify-content: left;
  width: 100%;
  margin: 0px;

  ${props =>
    !props.$disableScroll &&
    css`
      :hover {
        overflow: auto;
      }
    `}

  // Hide scrollbar in Chrome, Safari and Opera
  &::-webkit-scrollbar {
    display: none;
  }
  // Hide scrollbar in Firefox
  scrollbar-width: none;
  -ms-overflow-style: none;
`
