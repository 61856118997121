import React, { useState, useEffect } from "react"
import { Container } from "@opensea/ui-kit"
import { useInView } from "react-intersection-observer"
import { useAccountOrCollectionPageContext } from "@/components/layout/AccountOrCollectionPage/hooks/useAccountOrCollectionPageContext"
import { Z_INDEX } from "@/constants/zIndex"
import { Block } from "@/design-system/Block"
import { useMountEffect } from "@/hooks/useMountEffect"
import { getScrollbarWidth } from "@/lib/helpers/scrollbar"
import { NAV_HEIGHT_PX } from "@/styles/variables"
import { TabNavigation } from "./TabNavigation.react"
import { TabNavigationProps } from "./types"

type StickyTabNavigationProps = Omit<TabNavigationProps, "isStuck">

export const StickyTabNavigation = (props: StickyTabNavigationProps) => {
  const [isNavStuck, setIsNavStuck] = useState(false)
  const [scrollbarWidth, setScrollbarWidth] = useState(0)
  const { setHasStickyElement } = useAccountOrCollectionPageContext()
  const { ref, inView } = useInView({
    initialInView: true,
    rootMargin: `-${NAV_HEIGHT_PX + 12}px 0px ${NAV_HEIGHT_PX + 12}px 0px`,
    threshold: [0.1],
  })

  useMountEffect(() => {
    setScrollbarWidth(getScrollbarWidth())
  })

  useEffect(() => {
    setIsNavStuck(!inView)
    setHasStickyElement(!inView)
  }, [inView, setHasStickyElement])

  return (
    <>
      <span ref={ref} />
      <Block
        className="sticky bg-base-1"
        style={{
          marginLeft: `calc((100vw - 100%) / -2)`,
          // 1px for the border, 12px to account for extra whitespace in sticky nav above
          top: `${NAV_HEIGHT_PX - 13}px`,
          width: `calc(100vw - ${scrollbarWidth}px)`,
          zIndex: Z_INDEX.PHOENIX_HEADER,
        }}
      >
        <Container>
          <TabNavigation isStuck={isNavStuck} {...props} />
        </Container>
      </Block>
    </>
  )
}
