import styled from "styled-components"
import { Menu } from "@/design-system/Menu"

export const MenuItem = styled(Menu.Item)<{ $active?: boolean }>`
  display: flex;
  cursor: pointer;
  overflow: unset;
  background-color: ${({ $active, theme }) =>
    $active ? theme.colors.components.background.gray1 : "transparent"};
  align-items: center;

  &:hover {
    color: ${({ theme }) => theme.colors.text.primary};
  }
`
