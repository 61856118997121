import React from "react"
import { Flex, Text } from "@opensea/ui-kit"
import styled, { css } from "styled-components"
import { useRouter } from "@/hooks/useRouter"
import { useTranslate } from "@/hooks/useTranslate"
import { shortSymbolDisplay } from "@/lib/helpers/numberUtils"
import { stringifyQueryParams } from "@/lib/helpers/urls"
import type { TabItemProps } from "../../types"
import { MenuItem } from "../MenuItem"
import { NumberLabel, NumberLabelSkeleton } from "../NumberLabel"

export const TabItem = ({
  label,
  renderLabel,
  disabled,
  count,
  error,
  id,
  active,
  handleClick,
  baseUrl,
  path,
  isProfilePage,
  launchStatus,
  testId,
  responsive,
}: TabItemProps) => {
  const router = useRouter()
  const t = useTranslate("components")
  const tabLabel = (
    <StyledTabItemText
      $active={active}
      responsive={responsive}
      size="medium"
      weight="semibold"
    >
      {label}
    </StyledTabItemText>
  )

  const href = baseUrl
    ? isProfilePage
      ? `${baseUrl}/${id}`
      : `${baseUrl}${
          path ?? stringifyQueryParams({ ...router.queryParams, tab: id })
        }`
    : undefined

  const showCount = count !== undefined && count !== 0

  return (
    <StyledMenuItem
      $active={active}
      $disabled={disabled}
      aria-selected={!!active}
      data-testid={testId}
      direction="horizontal"
      disabled={disabled}
      href={href && !disabled ? href : undefined}
      padding={{ _: "12px 16px", lg: "12px 24px" }}
      role="tab"
      scroll={false}
      onClick={e => {
        if (handleClick && !disabled) {
          handleClick(id, e)
        }
      }}
    >
      {renderLabel ? renderLabel({ label, error, active }) : tabLabel}
      {showCount &&
        (count ? (
          <NumberLabel active={active} className="ml-2">
            {shortSymbolDisplay(count)}
          </NumberLabel>
        ) : (
          <NumberLabelSkeleton />
        ))}
      {!!launchStatus && (
        <Flex className="ml-1 mt-0.5 items-center">
          ·
          <LaunchStatusText className="ml-1">
            {launchStatus === "beta"
              ? t("launchBadge.status.beta", "Beta")
              : t("launchBadge.status.new", "New")}
          </LaunchStatusText>
        </Flex>
      )}
    </StyledMenuItem>
  )
}

export const StyledTabItemText = styled(Text.Body)<{ $active: boolean }>`
  color: ${({ $active, theme }) =>
    $active ? theme.colors.text.primary : theme.colors.text.secondary};
  white-space: nowrap;
  transition: color 0.25s ease-in-out;
`

const StyledMenuItem = styled(MenuItem)<{ $disabled?: boolean }>`
  ${({ $disabled }) =>
    !$disabled &&
    css`
      &:hover {
        ${StyledTabItemText} {
          color: ${({ theme }) => theme.colors.text.primary};
        }
      }
    `}
`

const LaunchStatusText = styled(Text.Body).attrs({
  size: "tiny",
})`
  color: ${({ theme }) => theme.colors.text.interactive.primary};
  letter-spacing: 1px;
  text-transform: uppercase;
`
